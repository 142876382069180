import './assets/css/global.css'
import { useQueryContext } from './core/contexts/query';
import { QueryClientProvider } from "react-query";
import Index from './pages/main';
import MainContextProvider from './core/contexts/main';
import './core/lang/i18n'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { APIProvider } from '@vis.gl/react-google-maps';

function App() {

  const { queryClient } = useQueryContext()
  const theme = extendTheme({
    styles: {
      global: {
        body: {
          bg: '#f5f5f5',
        }
      },
    },
  })
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <MainContextProvider>
          <APIProvider apiKey={'AlzaSyyTPoLHhklNcAJ-TR3M_hYKzGqS0fi0RBb'}>
            <Index />
          </APIProvider>
        </MainContextProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;
